<script setup>
/* eslint-disable */
  import { useStore } from 'vuex'
  import { ref, computed, watchEffect } from 'vue';
  import Bus from "../js/Bus";
  const props = defineProps(["modelValue", "data"]);
  const emits = defineEmits(["update:modelValue", "submit", "cancel"]);

  const show = computed({
    get() {
      return props.modelValue;
    },
    set(val) {
      emits("update:modelValue", val);
    }
  });

  const userInfo = computed({
    get () {
      return store.state.userInfo
    },
    set (val) {
      store.commit('updateUserInfo', val)
    }
  })

  const store = useStore()

  watchEffect(() => {
    if (show.value) {
      const { data } = props
    }
  })
</script>

<template>
  <div class="PayLoadding">
    <el-dialog v-model="show"
      style="width: 486px"
      :show-close="false"
      :close-on-press-escape="false"
      :close-on-click-modal="false">
      <div class="dialog-body">
        <div class="title">正在跳转至第三方平台支付，请稍等...</div>
      </div>

      <template #header>
        <div class="dialog-header">
          <div>第三方平台支付</div>
        </div>
      </template>
      <template #footer>
        <span class="dialog-footer">
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<style scoped lang="less">
.PayLoadding {
  :deep(.el-dialog) {
    .el-dialog__header {
      padding: 0;
      margin: 0;
    }
    .el-dialog__body {
      padding: 0;
    }
    .el-dialog__footer {
      padding: 0;
    }
    .dialog-header {
      height: 50px;
      padding: 0 24px;
      font-size: 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #999;
    }
    .dialog-body {
      padding: 32px 24px 48px;
      .title {
        text-align: left;
      }
      .desc {
        margin-top: 16px;
        text-align: left;
        color: #999;
      }
    }
    .dialog-footer {
     display: none;
    }
  }
}
</style>