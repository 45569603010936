import Layout from '@/layout'
import { getCookie } from '../assets/js/cookie';

let redirectUrl = "";

if (getCookie()) {
  redirectUrl = "/customer";
} else {
  redirectUrl = "/login";
}
const routes = [
  {
    path: '/',
    redirect: redirectUrl
  },
  {
      name: 'login',
      path: '/login',
      component: () => import('@/views/login')
  },
  {
    path: '/customer',
    component: Layout,
    children: [{
      path: '/customer',
      name: 'customer',
      component: () => import('@/views/customer')
    }]
  },
  {
    path: '/reward',
    component: Layout,
    children: [{
      path: '/reward',
      name: 'reward',
      component: () => import('@/views/reward')
    }]
  },
  {
    path: '/orders',
    component: Layout,
    children: [{
      path: '/orders',
      name: 'orders',
      component: () => import('@/views/orders')
    }]
  },
  {
    path: '/record',
    component: Layout,
    children: [{
      path: '/record',
      name: 'record',
      component: () => import('@/views/record')
    }]
  },
  {
    path: '/analysis',
    component: Layout,
    children: [{
      path: '/analysis',
      name: 'analysis',
      component: () => import('@/views/analysis')
    }]
  },
  {
    path: '/company',
    component: Layout,
    children: [{
      path: '/company',
      name: 'company',
      component: () => import('@/views/company')
    }]
  },
  {
    path: '/setting',
    component: Layout,
    children: [{
      path: '/setting',
      name: 'setting',
      component: () => import('@/views/setting')
    }]
  },
  {
    path: '/balance',
    component: Layout,
    children: [{
      path: '/balance',
      name: 'balance',
      component: () => import('@/views/balance')
    }]
  },
  {
    path: '/package',
    component: Layout,
    children: [{
      path: '/package',
      name: 'package',
      component: () => import('@/views/package')
    }]
  },
  {
    path: '/packageList',
    component: Layout,
    children: [{
      path: '/packageList',
      name: 'packageList',
      component: () => import('@/views/packageList')
    }]
  },
  {
    path: '/packages',
    component: Layout,
    children: [{
      path: '/packages',
      name: 'packages',
      component: () => import('@/views/packages')
    }]
  },
  {
    name: 'auth',
    path: '/auth',
    component: () => import('@/views/auth')
  },
  {
    name: 'authLogin',
    path: '/auth-login',
    component: () => import('@/views/auth/login')
  },
  {
    name: 'authCompany',
    path: '/auth-company',
    component: () => import('@/views/auth/company')
  },
  {
    name: 'companyAuth',
    path: '/company-auth',
    component: () => import('@/views/auth/company')
  },
  {
    name: 'authResult',
    path: '/auth-result',
    component: () => import('@/views/auth/authResult')
  },
  {
    name: 'authAgent',
    path: '/auth-agent',
    component: () => import('@/views/auth/agent')
  },
  {
    name: 'agentAuth',
    path: '/agent-auth',
    component: () => import('@/views/auth/agent')
  },
  {
    path: "/:catchAll(.*)", // 不识别的path自动匹配404
    name: 'NotFound',
    component: () => import('@/views/404')
  },
];

export default routes
