import request from '../utils/request'

export function getDomain(params) {
  return request({
    url: '/admin/agent/domain',
    method: 'GET',
    params
  })
}

export function putDomain(data) {
  return request({
    url: '/admin/agent/domain',
    method: 'PUT',
    data
  })
}

