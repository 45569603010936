import request from '../utils/request'

// login
export function ssoLogin(params) {
  return request({
    url: '/admin/agent/login',
    method: 'POST',
    params: params
  })
}


// 获取用户信息
export function ssoInfo(params) {
  return request({
    url: '/admin/agent/info',
    method: 'GET',
    params: params
  })
}

export function systemConfig(code) {
  return request({
    url: '/systemConfig/' + code,
    method: 'GET'
  })
}

export function ssoUpdatePassword(params) {
  return request({
    url: '/sso/updatePassword',
    method: 'POST',
    params: params
  })
}

export function ssoUpdatePhone(params) {
  return request({
    url: '/sso/updatePhone',
    method: 'POST',
    params: params
  })
}

export function ssoPhoneUpdatePassword(params) {
  return request({
    url: '/sso/phone/updatePassword',
    method: 'POST',
    params: params
  })
}

export function ssoNamePhone(params) {
  return request({
    url: '/sso/namePhone',
    method: 'GET',
    params: params
  })
}

// 资费说明
export function consumption(params) {
  return request({
    url: '/banner/withdraw',
    method: 'GET',
    params: params
  })
}

// 列表
export function writePage(params) {
  return request({
    url: '/write/page',
    method: 'GET',
    params: params
  })
}

// 创建软著
export function writeCreate (data) {
  return request({
    url: '/write/create',
    method: 'POST',
    data
  })
}

// 根据软件名称生成目的-描述-主要功能
export function writeDescription (data) {
  return request({
    url: '/write/description',
    method: 'POST',
    data
  })
}

// 根据软件名称返回大纲
export function writeOutline (data) {
  return request({
    url: '/write/outline',
    method: 'POST',
    data
  })
}

// 保存更新申请表
export function writeUpdate (data) {
  return request({
    url: '/write/update',
    method: 'POST',
    data
  })
}

// 保存更新大纲
export function writeOutlineUpdate (data) {
  return request({
    url: '/write/outline/update',
    method: 'POST',
    data
  })
}

// 根据大纲返回正文(同步)
export function writeMain (data) {
  return request({
    url: '/write/main',
    method: 'POST',
    data
  })
}

// 根据大纲返回正文(异步)
export function writeContent (data) {
  return request({
    url: '/write/content',
    method: 'POST',
    data
  })
}

// 正文列表
export function writeContentList(params) {
  return request({
    url: '/write/content',
    method: 'GET',
    params: params
  })
}

export function writeContentText(contentId) {
  return request({
    url: '/write/content/' + contentId,
    method: 'GET'
  })
}

// 保存更新正文
export function writeContentUpdate (data) {
  return request({
    url: '/write/content/update',
    method: 'POST',
    data
  })
}

// 根据功能正文返回html和截图
export function writeHtml (data) {
  return request({
    url: '/write/html',
    method: 'POST',
    data
  })
}

// 导出软著-完整的包
export function writeExport(params) {
  return request({
    url: '/write/export',
    method: 'GET',
    params: params
  })
}

// 导出软著-用户手册word
export function writeExportContent(params) {
  return request({
    url: '/write/export/content',
    method: 'GET',
    params: params
  })
}

export function writeDel (data) {
  return request({
    url: '/write/del/' + data.id,
    method: 'DELETE'
  })
}

// 获取申请表基本信息
export function writeSoftware(id) {
  return request({
    url: '/write/software/' + id,
    method: 'GET'
  })
}

// 改-扩写
export function writeRewrite (data) {
  return request({
    url: '/write/rewrite',
    method: 'POST',
    data
  })
}

// 文生图
export function TextToImage (data) {
  return request({
    url: '/write/textToImage',
    method: 'POST',
    data
  })
}

export function alipayForm(data) {
  return request({
    url: '/pay/repair/pay-form-web',
    method: 'POST',
    data
  })
}

export function createPayLog (data) {
  return request({
    url: '/pay/createPayLog',
    method: 'POST',
    data
  })
}

export function getInviteCode (data) {
  return request({
    url: '/inviteCode',
    method: 'GET',
    data
  })
}

export function getInviteCodeNew (data) {
  return request({
    url: '/inviteCode/new',
    method: 'GET',
    data
  })
}

export function writeEquities (params) { //权益校验
  return request({
    url: '/v2/write/equities',
    method: 'GET',
    params
  })
}

export function companyPackage (params) { //权益校验
  return request({
    url: '/company/package/package',
    method: 'GET',
    params
  })
}


export function writeHash (params) { //权益校验
  return request({
    url: '/v2/write/hash/' + params.writeId,
    method: 'GET',
    params
  })
}


